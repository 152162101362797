import React from "react"
import { navigate } from "gatsby"
import PuzzleForm from "../../components/puzzles/PuzzleForm"
import { Helmet } from "react-helmet-async"
import RequireAuth from "../../components/auth/RequireAuth"

const PuzzlesUpdatePage = ({ location }) => {
  const puzzle = location?.state?.puzzle

  if (!puzzle) {
    if (typeof window != "undefined") {
      navigate(`/puzzles`)
    }

    return <></>
  }

  return (
    <>
      <Helmet title={"Update Puzzle - JiggSwap"} />
      <RequireAuth />

      <div className="site-content">
        <div className="is-size-2">Update Puzzle: {puzzle.title}</div>
        <div className="is-size-4"></div>
        <hr />

        <PuzzleForm puzzle={puzzle} />
      </div>
    </>
  )
}

export default PuzzlesUpdatePage
