import React, { useEffect, useState } from "react"

import TagList from "components/puzzles/TagList"

const JiggswapTagInput = ({ initialTags, register, setValue }) => {
  const [tags, setTags] = useState(initialTags)
  const [errors] = useState([])
  register("tags", "")

  const maxTagLength = 30
  const maxTags = 10

  const delimiters = ["Tab", ",", ".", "Enter", ";"]
  const toIgnore = [",", ".", ";", "<", ">", "-", "/", "\\"]

  useEffect(() => {
    setValue("tags", tags)
  }, [tags, setValue])

  const keyPressed = (e) => {
    let currentTag = e.target.value

    for (var idx in toIgnore) {
      currentTag = currentTag.split(toIgnore[idx]).join("")
    }

    if (delimiters.includes(e.key) && currentTag.length) {
      addTag(currentTag.toLowerCase())

      currentTag = ""

      e.preventDefault()
    }

    if (toIgnore.includes(e.key)) {
      e.preventDefault()
    }

    e.target.value = currentTag
  }

  const addTag = (tag) => setTags([...new Set(tags.concat(tag))])

  const deleteTag = (e, tag) => {
    if (e && e.key === "Tab") {
    } else {
      setTags([...new Set(tags.filter((t) => t !== tag))])
    }
  }

  return (
    <>
      <div className="field">
        <label className="label" htmlFor="tag">
          Tags
        </label>

        <span className="is-size-6">
          Enter words or phrases that describe the puzzle, separated by commas or tabs. Examples:{" "}
          <span className="is-italic">Photograph, Kittens, Aquatic, Can you find?</span>
        </span>

        <div style={{ margin: "25px 0" }}>
          <span>Current Tags: </span>
          <TagList tags={tags} onDelete={deleteTag} />
        </div>
        <div className="control">
          <input
            aria-label="tag"
            id="tag"
            name="tag"
            type="text"
            className={`input` + (errors.length ? " is-danger" : "")}
            onKeyDown={keyPressed}
            maxLength={maxTagLength}
            disabled={tags.length === maxTags}
          />
        </div>
      </div>
    </>
  )
}

export default JiggswapTagInput
