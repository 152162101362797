import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"

import jiggswapApi from "api/jiggswap"
import { MapApiErrors } from "helpers/ErrorParser"

import JiggswapInput from "../forms/JiggswapInput"
import JiggswapTagInput from "../forms/JiggswapTagInput"
import JiggswapTextArea from "../forms/JiggswapTextArea"
import JiggswapImageUpload from "../forms/JiggswapImageUpload"
import JiggswapSelectList from "../forms/JiggswapSelectList"

import MissingPiecesOpts from "../../data/missing-pieces"

const PuzzleForm = ({ puzzle }) => {
  const { register, handleSubmit, errors, setError, setValue } = useForm({
    defaultValues: puzzle,
  })

  const imageBlobState = useState(null)
  const [imageUpdated, setImageUpdated] = useState(false)
  const [submitState, setSubmitState] = useState("ready")

  const isReady = submitState === "ready"
  const isSubmitting = submitState === "submitting"

  useEffect(() => {
    if (puzzle && puzzle.imageCdnUrl) {
      const buildImageBlob = async () => {
        var img = new Image()

        img.onload = () => {
          var canvas = document.createElement("canvas")
          canvas.width = img.width
          canvas.height = img.height

          var ctx = canvas.getContext("2d")
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

          canvas.toBlob((blob) => imageBlobState[1](blob))
        }

        img.setAttribute("crossorigin", "anonymous")
        img.src = puzzle.imageCdnUrl
      }

      buildImageBlob()
    }
    // eslint-disable-next-line
  }, [puzzle])

  const goBack = () => {
    if (typeof window == "undefined") {
      return
    }

    if (puzzle.puzzleId) {
      navigate("/puzzles/view?p=" + puzzle.puzzleId)
    } else {
      navigate("/puzzles")
    }
  }

  const submitForm = async (values) => {
    setSubmitState("submitting")

    var formData = new FormData()

    formData.append("numPieces", values.numPieces)

    for (var key in values) {
      formData.append(key, values[key])
    }

    if (imageUpdated && imageBlobState[0]) {
      formData.set("imageBlob", imageBlobState[0], "image")
    }

    try {
      let url = "/puzzles/"

      if (puzzle.puzzleId) {
        url += `${puzzle.puzzleId}`

        formData.append("puzzleId", puzzle.puzzleId)
      }

      var response = await jiggswapApi.post(url, formData)

      const puzzleId = response.data

      if (typeof window !== "undefined") {
        navigate(`/puzzles/view?p=${puzzleId}`)
      }
    } catch (err) {
      var apiErrors = MapApiErrors(err.response.data.errors)
      setSubmitState("ready")

      setError(apiErrors)
    }
  }

  return (
    <div>
      {isSubmitting && (
        <div className="has-text-centered">
          <div>
            <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          </div>
          <div className="has-text-centered is-size-3">Saving Puzzle...</div>
        </div>
      )}
      <form style={isReady ? undefined : { display: "none" }} onSubmit={handleSubmit(submitForm)} autoComplete="off">
        <div className="columns">
          <div className="column">
            <article className="message is-primary">
              <div className="message-header">Puzzle Details</div>
              <div className="message-body">
                <JiggswapInput name="title" type="text" register={register} errors={errors} />

                <JiggswapInput name="brand" type="text" register={register} errors={errors} />

                <JiggswapInput name="numPieces" label="# of Pieces" helptext="(total)" type="number" register={register} errors={errors} />

                <JiggswapImageUpload
                  setUpdated={setImageUpdated}
                  blobState={imageBlobState}
                  imageSize={{ width: 900, height: 600 }}
                  previewClass="image is-4by3"
                />

                <div className="control" style={{ marginTop: "25px" }}>
                  <div className="field">
                    <JiggswapTagInput initialTags={puzzle.tags} register={register} setValue={setValue} />
                    <span className="is-pulled-right">{/* <HelperText message={currentTag.length + " / " + maxTagLength} /> */}</span>
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div className="column">
            <article className="message is-primary">
              <div className="message-header">Puzzle Condition</div>
              <div className="message-body">
                <JiggswapSelectList
                  options={MissingPiecesOpts}
                  name="numPiecesMissing"
                  label="# Missing Pieces"
                  register={register}
                  errors={errors}
                />
              </div>
            </article>
          </div>
        </div>

        <JiggswapTextArea name="additionalNotes" label="Additional Notes" type="textarea" register={register} errors={errors} />

        <input type="hidden" aria-label="puzzle id" name="puzzleId" value={puzzle.id} />

        <hr />

        <div className="buttons">
          <button type="submit" className="button is-primary">
            Save Puzzle
          </button>
          <div type="button" className="button" onClick={goBack} onKeyPress={goBack} role="link" tabIndex={0}>
            Cancel
          </div>
        </div>
      </form>
    </div>
  )
}

export default PuzzleForm
