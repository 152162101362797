export default [
  { display: "-- Select One --", value: "" },
  { display: "Unknown", value: "Unknown" },
  { display: "0", value: "0" },
  { display: "1", value: "1" },
  { display: "2", value: "2" },
  { display: "3-5", value: "3-5" },
  { display: "6-10", value: "6-10" },
  { display: "More than 10", value: "> 10" },
]
