import React from "react"
import { useContext } from "react"
import { AuthContext } from "../../context/AuthContext"
import { useEffect } from "react"

const RequireAuth = () => {
  const {
    state: { isAuth, unloaded },
    setAuthModalVisibility,
  } = useContext(AuthContext)

  useEffect(() => {
    if (unloaded === undefined) {
      if (!isAuth) {
        setAuthModalVisibility(true, true)
      }
    }

    //eslint-disable-next-line
  }, [unloaded, isAuth])

  return <></>
}

export default RequireAuth
